<template>
  <div>
    <div id="main">
      <div class="banner">
        <img src="~@/assets/images/banner-news.jpg" alt="" />
      </div>
      <div class="container">
        <!--活动预告-->
        <div class="main-item clearfix">
          <div class="foreshow fl">
            <a-carousel autoplay style="width: 582px">
              <a
                :href="item.adlink"
                target="_blank"
                v-for="item in adList"
                :key="item.doi"
              >
                <img :src="item.pictruepath" alt="" />
                <p>{{ item.description }}</p>
                
              </a>
            </a-carousel>
          </div>
          <div class="activity fr">
            <div class="box">
              <div class="title">
                <span><i></i>活动预告</span>
                <a @click="goToView('/list/activityList')">更多<i></i></a>
              </div>
              <ul>
                <li v-for="item in activityList" :key="item.doi">
                  <b>【公告】</b>
                  <a @click="goDetail('newsDetail', item.doi,0)" :title="item.title">{{
                    item.title
                  }}</a>
                  <span>{{ item.pubdate }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!--新闻资讯-->
        <div class="main-item">
          <div class="box">
            <div class="title">
              <span><i></i>新闻资讯</span>
              <a @click="goToView('/list/news')">更多<i></i></a>
            </div>
            <ul class="news-list">
              <li v-for="item in newsList" :key="item.doi">
                <h4>
                  <a @click="goDetail('newsDetail', item.doi,1)" :title="item.title">{{
                    item.title
                  }}</a>
                </h4>
                <div class="news clearfix">
                  <a
                    @click="goDetail('newsDetail', item.doi,1)"
                    class="news-pic fl"
                    ><img :src="item.sys_fld_coverpath" alt=""
                  /></a>
                  <div class="news-info fr">
                    <p>
                      {{ item.digest }}
                    </p>
                    <div>
                      <span>{{ item.pubdate }}</span>
                      <a @click="goDetail('newsDetail', item.doi,1)">查看详情</a>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!--footer-->
  </div>
</template>

<script>
import * as Api from "@/services/activity";
import { adDoiList } from "@/config/constConfig";
import { replacePath,silceTime,isMobile } from "@/utils/util";

export default {
  name: "News",
  components: {},
  data() {
    return {
      adList: [],
      newsList: [],
      activityList: [],
    };
  },
  created() {
    this.Mobile()
    //获取活动预告 type 0活动  1 新闻
    this.getNewsList(4, 0);
    //获取新闻咨询
    this.getNewsList(4, 1);
    //获取广告
    this.getAd();
  },
  methods: {
    Mobile(){
      if(isMobile()){
        this.$router.push({ name: 'mobileNews' });
      }
    },
    goDetail(name, doi,type) {
      this.$router.push({ name: name, params: { doi: doi,type:type } });
    },
    goToView(path) {
      this.$router.push({ path: path });
    },
    //获取广告
    getAd() {
      Api.getAdList({
        page: 1,
        size: 10,
        type: adDoiList.activitiesIndexDoi,
      }).then((res) => {
        if (res.success) {
          const {
            data: { list },
          } = res;
          list.map((item) => {
            item.pictruepath = replacePath(item.pictruepath);
          });
          
          this.adList = list;
        } else {
          this.adList = [];
        }
      });
    },
    //获取活动信息
    getNewsList(size, type) {      
      Api.getNewsList({ page: 1, pageSize: size, typeid: type }).then((res) => {
        if (res.success) {
          const {
            data: { list },
          } = res;
          if (type == 0) {
            this.activityList = list.map((e) => {
              return {
                doi: e.urlid,
                title: e.title,
                pubdate: silceTime(e.pubdate),
              };
            });
          }else{
            this.newsList = list.map((e) => {
              return {
                doi: e.urlid,
                title: e.title,
                digest:e.digest,
                pubdate: silceTime(e.pubdate),
                sys_fld_coverpath:replacePath(e.sys_fld_coverpath)
              };
            });
          }         
        } else {
          this.activityList = [];
          this.newsList = [];
        }
      });
    },
  },
};
</script>
<style lang="less">
@import "~@/assets/css/news.less";
</style>
<style scoped>
.activity {
  width: 600px;
}
/* .news-list li h4 a {
  width: 50%;
} */
.news-list li h4 a:hover {
  color: #d4000e;
}
.news-pic img {
  width: 100%;
  height: 100%;
}
</style>
